<template>
  <div class="pa-5 fill-height">
    <v-card height="100%">
      <v-card-title>
        <v-row>
          <v-col class="d-flex align-center" cols="9">
            <span class="title-txt ma-5" @click="onClickBack">
              患者作成
            </span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider/>

      <v-card-text>
        <v-row class="mx-5 mb-0">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">名前：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="name"
                    variant="outlined"
                    dense
                    hide-details
                    disabled
                    :clearable="true">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">電話番号：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="phone"
                    variant="outlined"
                    dense
                    disabled
                    hide-details
                    :clearable="true">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-5 mb-0">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">生年月日：</span>
              </v-col>
              <v-col cols="6">
                <v-menu v-model="menuBirthday" :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <v-text-field
                        :model-value="formattedDateBirthday"
                        variant="outlined"
                        dense
                        readonly
                        disabled
                        v-bind="props"
                        hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateBirthday" hide-details @update:model-value="menuBirthday=false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">携帯番号：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="mobile"
                    variant="outlined"
                    dense
                    hide-details
                    disabled
                    :clearable="true">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-5 mb-0">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">性別：</span>
              </v-col>
              <v-col cols="6">
                <v-combobox
                    :items="genre"
                    item-title="text"
                    item-value="value"
                    v-model="sex"
                    readonly
                    variant="outlined"
                    hide-details>
                </v-combobox>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">メールアドレス：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="email"
                    variant="outlined"
                    dense
                    hide-details
                    disabled
                    :clearable="true">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-5 mb-0">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">身長：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="height"
                    variant="outlined"
                    dense
                    hide-details
                    disabled
                    :clearable="true">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">SpO2の最小値：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="minSpO2"
                    variant="outlined"
                    dense
                    hide-details
                    disabled
                    :clearable="true">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-5 mb-0">
          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">体重：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="weight"
                    variant="outlined"
                    dense
                    hide-details
                    disabled
                    :clearable="true">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">心拍数の最小値：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="minHeartRate"
                    variant="outlined"
                    dense
                    hide-details
                    disabled
                    :clearable="true">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-5 mb-0">
          <v-col cols="12" md="6">
          </v-col>

          <v-col cols="12" md="6">
            <v-row align="center">
              <v-col cols="4">
                <span class="detail-txt">心拍数の最大値：</span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="maxHeartRate"
                    variant="outlined"
                    dense
                    hide-details
                    disabled
                    :clearable="true">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="report-container mx-5 mb-2 mt-2 pa-5" v-if="patientCode">
          <span class="subtitle-txt">バイタルデータ</span>
          <v-row class="mt-5">
            <v-col cols="12" sm="2">
              <v-menu v-model="menuFrom" :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                  <v-text-field
                      :model-value="formattedDateFrom"
                      label="開始日"
                      variant="outlined"
                      dense
                      readonly
                      v-bind="props"
                      hide-details
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateFrom" hide-details @update:model-value="menuFrom=false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="2">
              <v-menu v-model="menuTo" :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                  <v-text-field
                      :model-value="formattedDateTo"
                      label="終了日"
                      variant="outlined"
                      dense
                      readonly
                      v-bind="props"
                      hide-details
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateTo" hide-details @update:model-value="menuTo=false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="1" class="d-flex align-center justify-center">
              <v-btn class="mx-5 base-btn-create-user elevation-0 small" @click="loadVitalData">検索</v-btn>
            </v-col>
          </v-row>
          <apex-chart type="line" :options="chartOptions" :series="series" height="350"></apex-chart>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog-error
        v-model.sync="showDialogError"
        :error-message="errorMessage"
    />
    <v-loader :show-default="false"></v-loader>
  </div>
</template>

<script>
import {PAGE} from "@/helpers/data-value-common";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {eventBus} from "@/helpers/eventBus";
import {useStore} from "vuex";
import axios from "axios";
import {extractErrorMessage} from "@/helpers/errorHandler";
import VLoader from "@/components/common/VLoader.vue";
import VDialogError from "@/components/layout/VDialogError.vue";
import CryptoJS from 'crypto-js'
import VueApexCharts from "vue3-apexcharts";
import {convertToDateWithOutTimeZone} from "@/helpers/utils";

export default {
  name: "PatientView",
  components: {
    'apex-chart': VueApexCharts,
    'v-loader': VLoader,
    'v-dialog-error': VDialogError,
  },
  setup() {
    let errorMessage = ref('');
    const showDialogError = ref(false);

    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const patientCode = ref(route.params.code);

    const genre = ref([
      { text: '男性', value: 'M' },
      { text: '女性', value: 'F' }
    ]);

    const menuBirthday = ref(false);
    const menuFrom = ref(false);
    const menuTo = ref(false);
    const dateBirthday = ref(new Date());
    const dateFrom = ref(new Date());
    const dateTo = ref(new Date());
    const name = ref('');
    const birthday = ref('');
    const sex = ref('');
    const phone = ref('');
    const mobile = ref('');
    const height = ref('');
    const weight = ref('');
    const email = ref('');
    const minSpO2 = ref(0);
    const minHeartRate = ref(0);
    const maxHeartRate = ref(0);

    const series = ref([
      { name: 'SpO2', data: [] },
      { name: 'Respiratory Rate', data: [] },
      { name: 'Heart Rate', data: [] },
    ]);

    const chartOptions = ref({
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      stroke: {
        width: [2, 2, 2],
      },
      xaxis: {
        type: 'datetime'
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy HH:mm'
        }
      }
    });

    const loadPatient = async () => {
      eventBus.emit('loader-show');

      await axios
          .get(`rms/patient/share/${patientCode.value}`, { withCredentials: true })
          .then(response => {
            name.value = response.data.name;
            birthday.value = response.data.birthday;
            dateBirthday.value = convertToDateWithOutTimeZone(response.data.birthday);
            sex.value = genre.value.find(g => g.value === response.data.sex);
            phone.value = response.data.phone;
            mobile.value = response.data.mobile;
            height.value = response.data.height;
            weight.value = response.data.weight;
            email.value = response.data.email;
            minSpO2.value = response.data.min_spo2;
            minHeartRate.value = response.data.min_heart_rate;
            maxHeartRate.value = response.data.max_heart_rate;
          })
          .catch(errors => {
            const { message, statusCode } = extractErrorMessage(errors);
            errorMessage.value = message

            if (statusCode === 400) {
              router.push({
                name: PAGE.ERROR.NAME,
                params: { message: encryptText(message) }
              });
            } else if (statusCode === 401) {
              router.push(PAGE.LOGIN.PATH);
              store.dispatch('setId', null);
              store.dispatch('setName', null);
              store.dispatch('setRole', null);
            } else {
              showDialogError.value = true
            }
          })
          .finally(() => {
            eventBus.emit('loader-hide');
          })
    }

    const encryptText = (message) => {
      const key = "aQwdesspq";
      return CryptoJS.AES.encrypt(message, key).toString();
    }

    const loadVitalData = async (isFirstTime = false) => {
      const params = {
        from: formattedDateFrom.value
      }

      if (isFirstTime) {
        params.to = formattedDateTo.value
      }

      eventBus.emit('loader-show');
      await axios
          .get(`rms/patient/vital-data/share/${patientCode.value}`, { params, withCredentials: true })
          .then(response => {
            processData(response.data);
          })
          .catch(errors => {
            const {message, statusCode} = extractErrorMessage(errors);
            errorMessage.value = message;

            if (statusCode === 401) {
              router.push(PAGE.LOGIN.PATH);
              store.dispatch('setId', null);
              store.dispatch('setName', null);
              store.dispatch('setRole', null);
            } else {
              showDialogError.value = true
            }
          })
          .finally(() => {
            eventBus.emit('loader-hide');
          })
    }

    const processData = (json) => {
      const rawData = json;
      const heartRateData = [];
      const spo2Data = [];
      const respiratoryRateData = [];

      rawData.forEach((item) => {
        const timestamp = new Date(item.timestamp).getTime();
        heartRateData.push([timestamp, item.heart_rate]);
        spo2Data.push([timestamp, item.spo2]);
        respiratoryRateData.push([timestamp, item.respiratory_rate]);
      });

      series.value[0].data = heartRateData;
      series.value[1].data = spo2Data;
      series.value[2].data = respiratoryRateData;
    };

    const formattedDateBirthday = computed(() => {
      return formatDate(dateBirthday.value);
    });

    const formattedDateFrom = computed(() => {
      return formatDate(dateFrom.value);
    });

    const formattedDateTo = computed(() => {
      return formatDate(dateTo.value);
    });

    function formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    const updateDateFrom = (event) => {
      dateFrom.value = event.target.value;
      menuFrom.value = false;
    }

    const onClickBack = () => {
      router.push(PAGE.PATIENTS.PATH);
    }

    onMounted(async () => {
      eventBus.emit('v-drawer-hide');

      if (patientCode.value) {
        dateFrom.value.setHours(0, 0, 0, 0);
        dateTo.value.setHours(0, 0, 0, 0);
        await loadPatient();
        await loadVitalData(true);
      }
    })

    return {
      patientCode,
      errorMessage,
      showDialogError,
      menuBirthday,
      menuFrom,
      menuTo,
      dateBirthday,
      dateFrom,
      dateTo,
      name,
      birthday,
      sex,
      phone,
      mobile,
      height,
      weight,
      email,
      minSpO2,
      minHeartRate,
      maxHeartRate,
      genre,
      series,
      chartOptions,
      formattedDateBirthday,
      formattedDateFrom,
      formattedDateTo,
      onClickBack,
      updateDateFrom,
      loadVitalData,
    }
  }
}

</script>

<style scoped lang="scss">
.title-txt {
  font-family: var(--font-secondary);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--color-primary-dark);
}

.detail-txt {
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: var(--color-black);
}

.subtitle-txt {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: 700;
}

.report-container {
  background: #EBEFF6;
}

.base-btn {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;

  &-create {
    color: var(--color-white) !important;
    background-color: var(--color-primary-dark) !important;
    border: 1px solid var(--color-primary-dark) !important;
  }

  &-delete {
    color: var(--color-white) !important;
    background-color: var(--color-red) !important;
    border: 1px solid var(--color-red) !important;
  }

  &-edit {
    color: var(--color-white) !important;
    background-color: var(--color-orange) !important;
    border: 1px solid var(--color-orange) !important;
  }

  &-share {
    color: var(--color-white) !important;
    background-color: var(--color-primary) !important;
    border: 1px solid var(--color-primary) !important;
  }
}
</style>