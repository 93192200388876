function extractErrorMessage(errorResponse) {
    let message = "予期しないエラーが発生しました。もう一度試してください。";
    let statusCode = null;

    if (errorResponse.response) {
        const res = errorResponse.response;
        statusCode = res.status;

        if (res.data && res.data.errors && res.data.errors.length) {
            const errorsMessages = res.data.errors.map(error => error.message || error).join('\n');

            if (statusCode === 400) {
                message = errorsMessages;
            } else if (statusCode === 403) {
                message = `${errorsMessages}`;
            } else if (statusCode === 404) {
                message = `${errorsMessages}`;
            } else if (statusCode === 500) {
                message = `${errorsMessages}`;
            } else {
                message = errorsMessages;
            }
        }
    }

    return { message, statusCode };
}

export { extractErrorMessage };
