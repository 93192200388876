import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});
export default createStore({
    state: {
        id: null,
        name: null,
        role: null,
    },
    mutations: {
        setId(state, id) {
            state.id = id;
        },
        setName(state, name) {
            state.name = name;
        },
        setRole(state, role) {
            state.role = role;
        }
    },
    actions: {
        setId(context, id) {
            context.commit('setId', id);
        },
        setName(context, email) {
            context.commit('setName', email);
        },
        setRole(context, role) {
            context.commit('setRole', role);
        }
    },
    getters: {
        getId(state) {
            return state.id;
        },
        getName(state) {
            return state.name;
        },
        getRole(state) {
            return state.role;
        },
    },
    plugins: [vuexLocal.plugin]
})