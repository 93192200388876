<template>
  <v-loader :show-default="true"></v-loader>
</template>

<script>
import VLoader from "@/components/common/VLoader.vue"
import {useStore} from "vuex";
import {computed, onMounted} from "vue";
import {useRouter} from "vue-router";
import {eventBus} from "@/helpers/eventBus";
import {PAGE} from "@/helpers/data-value-common";

export default {
  name: 'Index',
  components: {
    'v-loader': VLoader
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const idUser = computed(() => store.getters.getId);

    onMounted(() => {
      if (idUser.value != null && idUser.value !== 0) {
        eventBus.emit('v-drawer-show');
        router.push(PAGE.DASHBOARD.PATH);
      } else {
        eventBus.emit('v-drawer-hide');
        router.push(PAGE.LOGIN.PATH);
      }
    });
  }

}

</script>

<style scoped>

</style>