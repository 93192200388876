export const MENU_CODE = {
    EMPTY: 0,
    DASHBOARD: 1,
    USERS: 2,
    PATIENTS: 3,
    SHARED_LINKS: 4,
}

export const PAGE = {
    DASHBOARD: {
        PATH: "/dashboard",
        NAME: "ダッシュボード"
    },
    BASE: {
        PATH: "/index",
        NAME: "Index"
    },
    REDIRECT: {
        PATH: "/:catchAll(.*)",
        NAME: "Redirect"
    },
    ERROR: {
        PATH: "/error/:message?",
        NAME: "Error"
    },
    LOGIN: {
        PATH: "/login",
        NAME: "Login"
    },
    FORGOT: {
        PATH: "/forgot",
        NAME: "Forgot"
    },
    USERS: {
        PATH: "/users",
        NAME: "ユーザー"
    },
    USER_DETAIL: {
        PATH: "/users-detail/:id?",
        NAME: "UserDetail"
    },
    PATIENTS: {
        PATH: "/patients",
        NAME: "患者"
    },
    PATIENT_DETAIL: {
        PATH: "/patients-detail/:id?",
        NAME: "PatientDetail"
    },
    PATIENT_VIEW: {
        ABSOLUTE: "patients-view",
        PATH: "/patients-view/:code",
        NAME: "PatientView"
    },
    SHARED_LINKS: {
        PATH: "/share-links",
        NAME: "シェアリンク"
    },
    SHARED_LINK_DETAIL: {
        PATH: "/share-link-detail/:id?/:patientId?/:patientName?",
        NAME: "SharedLinkDetail"
    },
}