<template>
  <v-app class="app">
    <v-drawer></v-drawer>
    <v-main>
      <router-view :key="$route.fullPath"/>
    </v-main>

    <v-btn
        fab
        dark
        fixed
        bottom
        right
        color="primary"
        @click="clickMenu"
        class="v-btn--floating">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
  </v-app>
</template>

<script>

import VDrawer from "@/components/common/VDrawer.vue";
import {ref} from "vue";
import {eventBus} from "@/helpers/eventBus";

export default {
  name: 'App',
  components: {
    'v-drawer': VDrawer,
  },
  setup() {
    let drawer = ref(true);

    const clickMenu = () => {
      if (drawer.value) {
        eventBus.emit('v-drawer-open');
      } else {
        eventBus.emit('v-drawer-close');
      }
      drawer.value = !drawer.value
    }

    return {
      drawer,
      clickMenu
    }
  }
}
</script>

<style lang="scss">
:root {
  --font-primary: 'Roboto', sans-serif;
  --font-secondary: 'M PLUS 1p', sans-serif;

  --color-background: #F2F2F2;
  --color-primary-dark: #007EAF;
  --color-primary: #00A4E3;
  --color-primary-light: #4AC0EE;
  --color-orange: #E67E22;
  --color-white: #FFFFFF;
  --color-green: #4CAF50;
  --color-red: #FF5252;
  --color-black: #000000;
}

.app {
  background: var(--color-background) !important;
}

.v-btn--floating {
  display: none;
  position: fixed;
  right: 16px;
  bottom: 16px;
  width: 50px;
  height: 50px;
}

@media (max-width: 1280px) {
  .v-btn--floating {
    display: block;
  }
}

</style>