<template>
  <div class="bg-login d-flex align-center justify-center">
    <div class="bg-box d-flex flex-column align-center justify-center">
      <v-img
          src="@/assets/images/healthcare-icon.png"
          height="70"
          width="70"
          class="mb-3">
      </v-img>

      <h2 class="font-weight-bold text-center mb-5">Healthcare-Ecosystem</h2>

      <v-card class="bg-card mx-auto px-6 py-8">
        <v-form @submit.prevent="submit" action="#">
          <v-text-field
              bg-color="#E1D8D4"
              v-model="data.email"
              rounded="xl"
              variant="solo-filled"
              :clearable=true
              :flat=true
              label="Email"
          ></v-text-field>

          <v-text-field
              bg-color="#E1D8D4"
              :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible ? 'text' : 'password'"
              @click:append-inner="visible = !visible"
              v-model="data.password"
              rounded="xl"
              :clearable=true
              :flat=true
              variant="solo-filled"
              label="Password"
              name="email"
              autocomplete="email"
          ></v-text-field>

          <div class="text-center mb-4">
            <p class="text-caption text-red">{{ error }}</p>
          </div>

          <v-btn
              :block=true
              rounded="xl"
              color="#00A4E3"
              size="large"
              type="submit"
              variant="elevated"
              name="password"
              autocomplete="current-password"
          >
            ログイン
          </v-btn>
        </v-form>
      </v-card>
    </div>
    <v-loader :show-default="false"></v-loader>
  </div>
</template>
<script>

import {PAGE} from '@/helpers/data-value-common';
import {computed, onMounted, reactive} from "vue";
import axios from "axios";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {ref} from 'vue';
import {extractErrorMessage} from "@/helpers/errorHandler";
import {eventBus} from "@/helpers/eventBus";
import VLoader from "@/components/common/VLoader.vue";

export default {
  name: 'LoginView',
  components: {
    'v-loader': VLoader
  },
  setup() {
    const data = reactive({
      email: '',
      password: '',
    });
    const error = ref('')
    const visible = ref(false)
    const router = useRouter();
    const store = useStore();

    const idUser = computed(() => store.getters.getId);

    const isValidEmail = (email) => {
      return /\S+@\S+\.\S+/.test(email);
    };

    const submit = async () => {
      if (!isValidEmail(data.email)) {
        error.value = "有効なメールアドレスを入力してください";
        return;
      }

      eventBus.emit('loader-show');
      await axios.post('rms/login', data, {withCredentials: true})
          .then((response) => {
            store.dispatch('setId', response.data.user.id);
            store.dispatch('setName', response.data.user.name);
            store.dispatch('setRole', response.data.user.rol);
            router.push(PAGE.BASE.PATH);
          })
          .catch(errors => {
            const {message} = extractErrorMessage(errors);
            error.value = message
            store.dispatch('setId', null);
            store.dispatch('setName', null);
            store.dispatch('setRole', null);
          })
          .finally(() => {
            eventBus.emit('loader-hide');
          });
    }

    onMounted(() => {
      eventBus.emit('v-drawer-hide');

      if (idUser.value != null && idUser.value !== 0) {
        router.push(PAGE.BASE.PATH);
      }
    });

    return {
      data,
      error,
      visible,
      submit
    }
  }
}
</script>

<style>
.bg-login {
  background: #E7D8CD;
  height: 100vh;
  width: 100%;
}

.bg-card {
  background: #EFE6E1;
  width: 100%;
}

.bg-box {
  width: 28%;
}

@media (max-width: 600px) {
  .bg-box {
    width: 80%
  }
}

.text-center {
  text-align: center;
}

.text-link {
  color: #696969;
}
</style>