<template>
  <v-navigation-drawer class="menu" v-if="isShow" v-model="drawer">

    <v-list-item class="item-logo">
      <v-list-item-title><span class="title-logo">Healthcare Ecosystem</span></v-list-item-title>
    </v-list-item>

    <v-list class="menu-list">
      <v-list-item :class="activeMenu(CODES.DASHBOARD)" :to="PAGES.DASHBOARD.PATH">
        <template v-slot:prepend>
          <v-img class="icon-item mr-5" src="@/assets/icons/ic_dashboard.svg"></v-img>
        </template>
        <v-list-item-title class="submenu-title">{{ PAGES.DASHBOARD.NAME }}</v-list-item-title>
      </v-list-item>

      <v-list-item :class="activeMenu(CODES.USERS)" @click="navigateBasedOnRole">
        <template v-slot:prepend>
          <v-img class="icon-item mr-5" src="@/assets/icons/ic_users.svg"></v-img>
        </template>
        <v-list-item-title class="submenu-title">{{ PAGES.USERS.NAME }}</v-list-item-title>
      </v-list-item>

      <v-list-item :class="activeMenu(CODES.PATIENTS)" :to="PAGES.PATIENTS.PATH">
        <template v-slot:prepend>
          <v-img class="icon-item mr-5" src="@/assets/icons/ic_patients.svg"></v-img>
        </template>
        <v-list-item-title class="submenu-title">{{ PAGES.PATIENTS.NAME }}</v-list-item-title>
      </v-list-item>

      <v-list-item :class="activeMenu(CODES.SHARED_LINKS)" :to="PAGES.SHARED_LINKS.PATH">
        <template v-slot:prepend>
          <v-img class="icon-item mr-5" src="@/assets/icons/ic_shared_links.svg"></v-img>
        </template>
        <v-list-item-title class="submenu-title">{{ PAGES.SHARED_LINKS.NAME }}</v-list-item-title>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <v-list-item class="pa-3">
        <v-row align="center" justify="start" class="menu pa-0 ma-0">
          <v-col cols="auto">
            <v-img class="icon-item" src="@/assets/icons/ic_avatar.svg"></v-img>
          </v-col>
          <v-col>
            <div class="submenu-title">{{ nameUser }}</div>
          </v-col>
          <v-col cols="auto">
            <v-img class="icon-item" src="@/assets/icons/ic_logout.svg" @click="clickedLogout"></v-img>
          </v-col>
        </v-row>
      </v-list-item>
    </template>

  </v-navigation-drawer>
</template>

<script>

import {MENU_CODE, PAGE} from "@/helpers/data-value-common";
import {computed, onMounted, onUnmounted, ref} from "vue";
import {eventBus} from "@/helpers/eventBus";
import axios from "axios";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {extractErrorMessage} from "@/helpers/errorHandler";

export default {
  name: 'VDrawer',
  setup() {
    const CODES = ref(MENU_CODE)
    const PAGES = ref(PAGE)

    let isShow = ref(true)
    const drawer = ref(true)
    const activeItem = ref(MENU_CODE.EMPTY)

    const router = useRouter();
    const store = useStore();

    const idUser = computed(() => store.getters.getId);
    const nameUser = computed(() => store.state.name);
    const roleUser = computed(() => store.state.role);

    const showMenu = () => {
      isShow.value = true
    }

    const hideMenu = () => {
      isShow.value = false
    }

    const openMenu = () => {
      drawer.value = true
    }

    const closeMenu = () => {
      drawer.value = false
    }

    const activeMenu = (code) => {
      return activeItem.value === code ? "menu_active" : ""
    }

    const setActiveItem = (code) => {
      activeItem.value = code
    }

    const navigateBasedOnRole = () => {
      if (roleUser.value === 'ADMIN') {
        router.push(PAGE.USERS.PATH);
      } else {
        router.push({
          name: PAGE.USER_DETAIL.NAME,
          params: {id: idUser.value}
        });
      }
    };

    const clickedLogout = async () => {
      await axios
          .post('rms/logout', {}, {withCredentials: true})
          .then(() => {
            store.dispatch('setId', null);
            store.dispatch('setEmail', null);
            router.push(PAGE.LOGIN.PATH);
          }).catch((errors) => {
            const {message, statusCode} = extractErrorMessage(errors);
            console.log(message);
            if (statusCode === 403) {
              router.push(PAGE.LOGIN.PATH);
              store.dispatch('setId', null);
            }
          });
    }

    onMounted(() => {
      eventBus.on('v-drawer-show', showMenu);
      eventBus.on('v-drawer-hide', hideMenu);
      eventBus.on('v-drawer-open', openMenu);
      eventBus.on('v-drawer-close', closeMenu);
      eventBus.on('v-drawer-active-item', setActiveItem);
    });

    onUnmounted(() => {
      eventBus.off('v-drawer-show', showMenu);
      eventBus.off('v-drawer-hide', hideMenu);
      eventBus.off('v-drawer-open', openMenu);
      eventBus.off('v-drawer-close', closeMenu);
      eventBus.off('v-drawer-active-item', setActiveItem);
    });


    return {
      CODES,
      PAGES,
      isShow,
      drawer,
      activeItem,
      nameUser,
      roleUser,
      showMenu,
      hideMenu,
      activeMenu,
      clickedLogout,
      setActiveItem,
      navigateBasedOnRole
    }
  }
}
</script>

<style lang="scss">
.menu {
  background: var(--color-primary-dark);

  &_active {
    background: var(--color-primary);
    border-left: 5px solid var(--color-orange);
  }

  &-footer {
    width: 100%;
  }
}

.icon-item {
  color: var(--color-white) !important;
  height: 24px;
  width: 24px;
}

.item-logo {
  height: 98px;
}

.title-logo {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-white);
  line-height: normal;
}

.submenu-title {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-white);
}

</style>