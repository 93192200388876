<template>
  <v-overlay
      :model-value="isShow"
      class="align-center justify-center"
  >
    <v-progress-circular
        indeterminate
        color="blue"
        size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>

import { eventBus } from '@/helpers/eventBus';

export default {
  data() {
    return {
      isShow: false,
    }
  },
  props: {
    showDefault: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    showLoader() {
      this.isShow = true;
    },
    hideLoader() {
      this.isShow = false;
    }
  },
  created() {
    this.isShow = this.showDefault;
    eventBus.on('loader-show', this.showLoader);
    eventBus.on('loader-hide', this.hideLoader);
  },
  unmounted() {
    eventBus.off('loader-show', this.showLoader);
    eventBus.off('loader-hide', this.hideLoader);
  },
}
</script>

<style scoped>
</style>